import React, { useState } from "react";
import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "../../App.css";
import { Box, Button, Grow } from "@mui/material";
import { useEffect } from "react";
import logo from "../../img/logo.png";
import { useSearchParams } from "react-router-dom";

const NewNav = () => {
  const navRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [scroll, setscroll] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 200 || window.location.pathname !== "/") {
      setscroll(true);
    } else {
      setscroll(false);
    }
  };

  window.addEventListener("scroll", handleScroll);
  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <>
      <header style={{ background: "#fdfcf6" }}>
        <a href="/">
          {window.location.pathname !== "/" ? (
            <Box
              src={logo}
              component={"img"}
              alt="smthng here"
              style={{ marginTop: "10px" }}
              height="80px"
              width="80px"
            />
          ) : (
            <Grow
              in={scroll}
              style={{ transformOrigin: "right" }}
              timeout={{ exit: 1000, enter: 1000 }}
            >
              <img src={logo} alt="smthng here" height="80px" width="80px" />
            </Grow>
          )}
        </a>

        <nav ref={navRef}>
          <div className="dropdown link1">
            <a href="/" size="large" style={{ textAlign: "center" }}>
              MinMini
            </a>
            <span className="dropdown-content">
              <a href="/uber-uns">Über uns</a>
              <a href="/faq">FAQ</a>
              <a href="/letter">AGB</a>
            </span>
          </div>
          <a href="/booking-process" className="link" size="large">
            {" "}
            Regal Mieten
          </a>
          {localStorage.getItem("authUser") ||
          searchParams.get("login") === true ? (
            <a
              href={
                localStorage.getItem("type") === "admin"
                  ? "/bookings-admin"
                  : "/my-profile/dashboard"
              }
              size="large"
              className="link"
            >
              Mein Bereich
            </a>
          ) : (
            <a href="/login" size="large" className="link">
              Login
            </a>
          )}
          <a
            href="https://www.minmini-shop.de"
            className="link"
            target="__blank"
            style={{ color: "white", textDecoration: "none" }}
            size="large"
          >
            minmini-shop
          </a>

          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </header>
      <div style={{ height: "80px" }}></div>
    </>
  );
};

export default NewNav;
