import {
  House,
  Logout,
  Category,
  Receipt,
  DomainDisabled,
  Dashboard,
} from '@mui/icons-material';
import { Box, Button, Container } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import http from '../http';

const AdminNav = (props) => {
  const navigate = useNavigate();
  const handleActive = (value) => {
    navigate(value);
    window.location.reload();
  };
  const pathName = window.location.pathname;
  const path = [
    '/booking-process',
    '/my-profile',
    '/faq',
    '/letter',
    '/login',
    '/',
  ];

  const handleLogout = async () => {
    try {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `/auth/logout`,
        headers: {},
      };

      await http.request(config);

      localStorage.clear();
      handleActive('/');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container sx={{}}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: { xs: 'start', sm: 'center' },
          gap: '1rem',
          overflow: 'scroll',
          width: '100%',
          px: '0rem',
          py: '1rem',
          bgcolor: 'rgb(253, 252, 246)',
        }}
      >
        <Button
          component={Link}
          to={'/bookings-admin'}
          sx={{
            textTransform: 'capitalize',
            px: '1rem',
            borderRadius: '.8rem',
          }}
          style={{
            background: pathName == '/bookings-admin' ? '#e1b6b4' : '',
            color: pathName == '/bookings-admin' ? 'GreyText' : 'black',
          }}
          startIcon={<Receipt />}
        >
          Bookings
        </Button>
        {/* <Button
          component={Link}
          to={"/admin-profile"}
          sx={{
            textTransform: "capitalize",
            px: "1rem",
            borderRadius: ".8rem",
          }}
          style={{
            background: pathName == "/admin-profile" ? "#e1b6b4" : "",
            color: pathName == "/admin-profile" ? "GreyText" : "black",
          }}
          startIcon={<Dashboard />}
        >
          {" "}
          Mein Bereich
        </Button> */}
        <Button
          component={Link}
          to={'/users-admin'}
          sx={{
            textTransform: 'capitalize',
            px: '1rem',
            borderRadius: '.8rem',
          }}
          style={{
            background: pathName == '/users-admin' ? '#e1b6b4' : '',
            color: pathName == '/users-admin' ? 'GreyText' : 'black',
          }}
          startIcon={<Receipt />}
        >
          Users
        </Button>
        <Button
          component={Link}
          to={'/stands-admin'}
          sx={{
            textTransform: 'capitalize',
            px: '1rem',
            borderRadius: '.8rem',
          }}
          style={{
            background: pathName == '/stands-admin' ? '#e1b6b4' : '',
            color: pathName == '/stands-admin' ? 'GreyText' : 'black',
          }}
          startIcon={<Receipt />}
        >
          Stands
        </Button>
        <Button
          component={Link}
          to={'/products-admin'}
          sx={{
            textTransform: 'capitalize',
            px: '1rem',
            borderRadius: '.8rem',
          }}
          style={{
            background: pathName == '/products-admin' ? '#e1b6b4' : '',
            color: pathName == '/products-admin' ? 'GreyText' : 'black',
          }}
          startIcon={<Receipt />}
        >
          Products
        </Button>
        <Button
          component={Link}
          to={'/categories-admin'}
          sx={{
            textTransform: 'capitalize',
            px: '1rem',
            borderRadius: '.8rem',
          }}
          style={{
            background: pathName == '/categories-admin' ? '#e1b6b4' : '',
            color: pathName == '/categories-admin' ? 'GreyText' : 'black',
          }}
          startIcon={<Category />}
        >
          Categories
        </Button>
        <Button
          component={Link}
          to={'/coupons-admin'}
          sx={{
            textTransform: 'capitalize',
            px: '1rem',
            borderRadius: '.8rem',
          }}
          style={{
            background: pathName == '/coupons-admin' ? '#e1b6b4' : '',
            color: pathName == '/coupons-admin' ? 'GreyText' : 'black',
          }}
          startIcon={<Category />}
        >
          Coupons
        </Button>
        <Button
          component={Link}
          to={'/referral-users-admin'}
          sx={{
            textTransform: 'capitalize',
            px: '1rem',
            borderRadius: '.8rem',
          }}
          style={{
            background: pathName == '/referral-users-admin' ? '#e1b6b4' : '',
            color: pathName == '/referral-users-admin' ? 'GreyText' : 'black',
          }}
          startIcon={<Receipt />}
        >
          Referrals
        </Button>
        <Button
          component={Link}
          to={'/holidays-admin'}
          sx={{
            textTransform: 'capitalize',
            px: '1rem',
            borderRadius: '.8rem',
          }}
          style={{
            background: pathName == '/holidays-admin' ? '#e1b6b4' : '',
            color: pathName == '/holidays-admin' ? 'GreyText' : 'black',
          }}
          startIcon={<DomainDisabled />}
        >
          Holidays
        </Button>
        <Button
          component={Link}
          to={'/invoices-admin'}
          sx={{
            textTransform: 'capitalize',
            px: '1rem',
            borderRadius: '.8rem',
          }}
          style={{
            background: pathName == '/invoices-admin' ? '#e1b6b4' : '',
            color: pathName == '/invoices-admin' ? 'GreyText' : 'black',
          }}
          startIcon={<Receipt />}
        >
          Invoices
        </Button>
        <Button
          sx={{
            textTransform: 'capitalize',
            px: '1rem',
            borderRadius: '.8rem',
          }}
          style={{
            color: 'black',
          }}
          onClick={handleLogout}
          startIcon={<Logout />}
        >
          Logout
        </Button>
      </Box>
      {props.children}
    </Container>
  );
};

export default AdminNav;
