import * as React from 'react';
import { useTable, useSortBy } from 'react-table';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import http from '../../http';
import moment from 'moment';
import { isSameDay, parseISO } from 'date-fns';
import {
  Box,
  Button,
  MenuItem,
  Grid,
  InputLabel,
  Select,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
} from '@mui/material';
import Pagination from '../my-profile/Pagination';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Countdown from 'react-countdown';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { EXTRAS, PACKAGES } from '../bookingProcess/constants';

export default function BookingsLists() {
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [products, setProducts] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalCount, setTotalCount] = React.useState(0);
  const [standList, setStandList] = React.useState([]);
  const [userInfo, setUser] = React.useState([]);
  const [update, setUpdate] = React.useState(false);
  const [isUserModel, setIsUserModel] = React.useState(false);
  const [isBookingModel, setIsBookingModel] = React.useState(false);
  const [name, setName] = React.useState(new Date());
  const [stand_id, setStand_id] = React.useState();
  const [updatedStartDate, setUpdatedStartDate] = React.useState(null);
  const [updatedEndDate, setUpdatedEndDate] = React.useState(null);
  const [updatedRentPrice, setUpdatedRentPrice] = React.useState(null);
  const [updatedItemId, setUpdatedItemId] = React.useState(null);
  const [shelfList, setShelfList] = React.useState([]);
  const [disabledDates, setDisabledDates] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState('');
  const [filterProducts, setFilterProducts] = React.useState([]);
  const [currentTab, setCurrentTab] = React.useState('');
  const [userType, setUserType] = React.useState('new');
  const [users, setUsers] = React.useState([]);
  const [isCouponApplied, setIsCouponApplied] = React.useState(false);
  const [isReferralCodeApplied, setIsReferralCodeApplied] =
    React.useState(false);
  const [packages, setPackages] = React.useState(PACKAGES);
  const [extras, setExtras] = React.useState(EXTRAS);

  const [isSearchLoading, setIsSearchLoading] = React.useState(false);
  const [selectedColumn, setSelectedColumn] = React.useState('');
  const [filterValue, setFilterValue] = React.useState('');

  const searchableColumns = [
    { label: 'Booking Id', value: 'id' },
    { label: 'Stand Id', value: 'stand_id' },
    { label: 'User Id', value: 'user_id' },
    { label: 'Duration', value: 'duration' },
    { label: 'Package', value: 'collect_product_by' },
    { label: 'Rent Price', value: 'rent_price' },
    { label: 'Status', value: 'status' },
  ];

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const handleClickOpen = () => {
    setIsUserModel(true);
  };

  const handleUserModelClose = () => {
    setIsUserModel(false);
  };

  const handleClickBookingOpen = () => {
    setIsBookingModel(true);
  };

  const handleBookingModelClose = () => {
    setIsBookingModel(false);
    formik.resetForm();
  };

  const handleUpdateClose = () => {
    setUpdate(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    fetchBookingsData(1);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    fetchBookingsData(newPage);
  };

  const handleSearch = async () => {
    if (selectedColumn && filterValue) {
      setIsSearchLoading(true);
      try {
        await fetchBookingsData(1, {
          column: selectedColumn,
          value: filterValue,
        });
      } catch (error) {
        console.error('Error during search:', error);
      } finally {
        setIsSearchLoading(false);
      }
    } else {
      alert('Please select a column and enter a value to filter.');
    }
  };

  React.useEffect(() => {
    http
      .request({
        method: 'get',
        maxBodyLength: Infinity,
        url: '/holidays',
        headers: {},
      })
      .then((response) => {
        const { data } = response.data;
        setDisabledDates(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const fetchBookingsData = async (pageNumber = 1, filter = {}) => {
    try {
      let query = `/booking/all?page=${pageNumber}&limit=${rowsPerPage}`;

      if (filter.column && filter.value) {
        query += `&filterColumn=${filter.column}&filterValue=${filter.value}`;
      }

      const response = await http.get(query);
      const { data, pagination } = response.data;

      setProducts(data);
      setTotalPages(pagination.totalPages);
      setTotalCount(pagination.totalCount);
    } catch (error) {
      console.log('Error fetching bookings data', error);
    }
  };

  React.useEffect(() => {
    fetchBookingsData(page);
  }, [page, rowsPerPage]);

  const fetchAvailableStands = async (startDate, endDate) => {
    try {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `/stand/available_stands_by_dates?startDate=${startDate}&endDate=${endDate}`,
        headers: {},
      };

      const response = await http.request(config);

      const { data } = response.data;
      setStandList(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = React.useCallback(async (startDate, endDate) => {
    await fetchAvailableStands(startDate, endDate);
  }, []);

  React.useEffect(() => {
    if (updatedStartDate && updatedEndDate) {
      fetchAvailableStands(updatedStartDate, updatedEndDate);
    }
  }, [updatedStartDate, updatedEndDate]);

  const updateProduct = React.useCallback(() => {
    let data = JSON.stringify({
      stand_id: stand_id,
      startDate: updatedStartDate,
      endDate: updatedEndDate,
      rentPrice: updatedRentPrice,
    });

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `/booking/update-booking/booking-id/${updatedItemId}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    http
      .request(config)
      .then((response) => {
        alert('Booking stand is Updated successfully');
        window.location.reload();
      })
      .catch((error) => {
        alert('Failed to Updated stand. Please try again');
        window.location.reload();
      });
  }, [
    http,
    stand_id,
    updatedStartDate,
    updatedEndDate,
    updatedRentPrice,
    updatedItemId,
  ]);

  const cancelBooking = async (id) => {
    try {
      const response = await http.get(
        `/booking/cancel-booking/booking-id/${id}`
      );
      if (response.status === 201) {
        alert('Booking cancelled successfully');
        window.location.reload();
      } else {
        alert('Failed to cancel the booking');
      }
    } catch (error) {
      console.error('Error cancelling the booking:', error);
      alert(
        'An error occurred while cancelling the booking. Please try again.'
      );
    }
  };

  const getFinalPrice = (finalPrice) => {
    const originalPrice = parseFloat(finalPrice);
    let priceAfterCoupon = originalPrice;
    if (
      couponDetail &&
      Object.keys(couponDetail).length > 0 &&
      couponDetail.discount_amount > 0
    ) {
      const couponDiscount =
        couponDetail.discount_type === 'fixed'
          ? couponDetail.discount_amount
          : originalPrice * (couponDetail.discount_amount / 100);
      priceAfterCoupon -= couponDiscount;
    }

    let finalPriceAfterAllDiscounts = priceAfterCoupon;
    if (
      referralDetail &&
      Object.keys(referralDetail).length > 0 &&
      referralDetail.discount_amount > 0
    ) {
      const referralDiscount =
        referralDetail.discount_type === 'fixed'
          ? referralDetail.discount_amount
          : priceAfterCoupon * (referralDetail.discount_amount / 100);
      finalPriceAfterAllDiscounts -= referralDiscount;
    }

    finalPriceAfterAllDiscounts = Math.max(finalPriceAfterAllDiscounts, 0);

    return finalPriceAfterAllDiscounts.toFixed(2);
  };

  const generateRandomPassword = (length) => {
    const charset =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    return window.crypto
      .getRandomValues(new Uint8Array(length))
      .reduce((password, number) => {
        return password + charset.charAt(number % charset?.length);
      }, '');
  };

  const sendEmail = async (selectDayItem, extraItem, values, data) => {
    let bookingData = {
      bookingStand: JSON.stringify(selectDayItem?.title),
      userDetails: JSON.stringify(data),
      selectedStartDate: JSON.stringify(values.start_date),
      selectedEndDate: JSON.stringify(values.end_date),
      selectedPackage: JSON.stringify(selectDayItem),
      extraItem: JSON.stringify(extraItem),
      selectedDayItem: JSON.stringify(selectDayItem),
      selectedStand: JSON.stringify(
        standList.find((stand) => stand.id === values.shelf)
      ),
      newsOffer: JSON.stringify(true),
    };

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: '/booking/email',
      headers: { 'Content-Type': 'application/json' },
      data: JSON.stringify(bookingData),
    };

    try {
      await http.request(config);
      alert('Booking has been created and confirmation email has been sent!');
    } catch (e) {
      console.error('Error sending confirmation email', e);
      alert(
        'Booking has been created but confirmation email could not be sent!'
      );
    } finally {
      window.location.reload();
    }
  };

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      userType: userType,
      package: '',
      start_date: '',
      end_date: '',
      shelf: '',
      email: '',
      firstName: '',
      lastName: '',
      country: '',
      zip: '',
      iban: '',
      address: '',
      phoneNumber: '',
      existingUser: '',
      additionalServices: '',
    },

    onSubmit: (values) => {
      let errors = {};

      if (values.userType === 'new') {
        if (!values.firstName) errors.firstName = 'First name is required';
        if (!values.lastName) errors.lastName = 'Last name is required';
        if (!values.email) errors.email = 'Email is required';
        if (!values.country) errors.country = 'Country is required';
        if (!values.zip) errors.zip = 'Zip code is required';
        if (!values.iban) errors.iban = 'IBAN is required';
        if (!values.address) errors.address = 'Address is required';
        if (!values.phoneNumber)
          errors.phoneNumber = 'Phone number is required';
      } else if (values.userType === 'existing') {
        if (!values.existingUser)
          errors.existingUser = 'Existing user is required';
      }

      if (Object.keys(errors).length) {
        formik.setErrors(errors);
        return;
      }

      let finalPrice = 0;
      let calculatedDuration = null;
      let selectDayItem = null;
      let extraItem = null;

      if (values.package === 'custom') {
        finalPrice = parseFloat(values.rent_price);
        console.log(finalPrice);

        const startDate = new Date(values.start_date);
        const endDate = new Date(values.end_date);
        calculatedDuration =
          Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;

        if (calculatedDuration <= 0) {
          alert('End date must be later than start date.');
          return;
        }
      } else {
        selectDayItem = packages.find(
          (item) => item.id === Number(values.package)
        );
        extraItem = extras.find(
          (item) => item.id === Number(values.additionalServices)
        );
        if (
          monthNames[new Date(values?.start_date).getMonth()] == 'September' ||
          monthNames[new Date(values?.start_date).getMonth()] == 'October'
        ) {
          finalPrice =
            selectDayItem.id === 5
              ? Number(selectDayItem?.price) +
                Number(extraItem?.price ? extraItem?.price : 0) +
                0
              : Number(selectDayItem?.price) +
                Number(extraItem?.price ? extraItem?.price : 0);
          finalPrice =
            isCouponApplied || isReferralCodeApplied
              ? getFinalPrice(finalPrice)
              : finalPrice;
        } else {
          finalPrice =
            selectDayItem.id === 5
              ? Number(selectDayItem?.price) +
                Number(extraItem?.price ? extraItem?.price : 0) +
                0
              : Number(selectDayItem?.price) +
                Number(extraItem?.price ? extraItem?.price : 0);
          finalPrice =
            isCouponApplied || isReferralCodeApplied
              ? getFinalPrice(finalPrice)
              : finalPrice;
        }
      }

      if (userType === 'new') {
        let data = JSON.stringify({
          email: values.email,
        });

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: '/auth/is-email-already-exist',
          headers: {
            'Content-Type': 'application/json',
          },
          data: data,
        };

        http
          .request(config)
          .then((response) => {
            const { message } = response?.data;
            if (message === 'User exist with this email') {
              alert('User with this email already exists!');
              return;
            } else {
              const passwordString = generateRandomPassword(10);

              let data = {
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                status: 'active',
                password: `${passwordString}`,
                confirm_password: `${passwordString}`,
                country: values.country,
                zip: values.zip,
                iban: values.iban,
                address: values.address,
                phone_number: values.phoneNumber,
                news_offer: true,
              };

              let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: '/auth/register',
                headers: { 'Content-Type': 'application/json' },
                data: JSON.stringify(data),
              };

              http
                .request(config)
                .then((response) => {
                  const { data } = response;
                  if (data?.status) {
                    let bookingData = JSON.stringify({
                      shelf_type: 1,
                      stand_id: values.shelf,
                      duration:
                        values.package === 'custom'
                          ? calculatedDuration
                          : selectDayItem?.duration,
                      start: values.start_date,
                      end: values.end_date,
                      collect_product_by: extraItem?.title,
                      rebook: 0,
                      rent_price:
                        isCouponApplied || isReferralCodeApplied
                          ? getFinalPrice(finalPrice)
                          : parseFloat(finalPrice),
                      original_price:
                        isCouponApplied || isReferralCodeApplied
                          ? getFinalPrice(finalPrice)
                          : parseFloat(finalPrice),
                      status: 'active',
                      prepare: moment(values.start_date)
                        .locale('en')
                        .subtract(1, 'days')
                        .format('LL'),
                      user_id: data?.data.id,
                      description: ' ',
                      coupon: isCouponApplied ? couponDetail?.code : 'null',
                      referral_code: isReferralCodeApplied
                        ? referralCode
                        : null,
                    });

                    let config = {
                      method: 'post',
                      maxBodyLength: Infinity,
                      url: '/booking/admin_place_order?sendEmail=false',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      data: bookingData,
                    };

                    http
                      .request(config)
                      .then((response) => {
                        sendEmail(selectDayItem, extraItem, values, data);
                      })
                      .catch(function (error) {
                        if (error.response) {
                          // The request was made and the server responded with a status code
                          // that falls out of the range of 2xx
                          console.log(error.response.data);
                          alert(error.response.data.message);
                        } else if (error.request) {
                          // The request was made but no response was received
                          // `error.request` is an instance of XMLHttpRequest in the browser
                          // and an instance of http.ClientRequest in node.js
                          console.log(error.request);
                          alert(error.request);
                        } else {
                          // Something happened in setting up the request that triggered an Error
                          console.log('Error', error.message);
                          alert(error.message);
                        }
                      });
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        let bookingData = JSON.stringify({
          shelf_type: 1,
          stand_id: values.shelf,
          duration:
            values.package === 'custom'
              ? calculatedDuration
              : selectDayItem?.duration,
          start: values.start_date,
          end: values.end_date,
          collect_product_by: extraItem?.title,
          rent_price:
            isCouponApplied || isReferralCodeApplied
              ? getFinalPrice(finalPrice)
              : parseFloat(finalPrice),
          original_price:
            isCouponApplied || isReferralCodeApplied
              ? getFinalPrice(finalPrice)
              : parseFloat(finalPrice),
          status: 'active',
          prepare: moment(values.start_date)
            .locale('en')
            .subtract(1, 'days')
            .format('LL'),
          user_id: values.existingUser,
          description: ' ',
          coupon: isCouponApplied ? couponDetail?.code : 'null',
          referral_code: isReferralCodeApplied ? referralCode : null,
        });

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: '/booking/admin_place_order',
          headers: {
            'Content-Type': 'application/json',
          },
          data: bookingData,
        };

        http
          .request(config)
          .then((response) => {
            sendEmail(
              values.package === 'custom' ? 'Custom' : selectDayItem,
              extraItem,
              values,
              users.find((user) => user.id === values.existingUser)
            );
          })
          .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              alert(error.response.data.message);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser
              // and an instance of http.ClientRequest in node.js
              console.log(error.request);
              alert(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
              alert(error.message);
            }
          });
      }
    },

    validationSchema: Yup.object({
      package: Yup.string().required('Package is required'),
      start_date: Yup.string().required('Start date is required'),
      end_date: Yup.string().required('End date is required'),
      shelf: Yup.string().required('Stand ID is required'),
      additionalServices: Yup.string().required(
        'Additional services are required'
      ),
    }),
  });

  const calcWorkingDays = (fromDate, days) => {
    var count = 1;
    while (count < days) {
      fromDate.setDate(fromDate.getDate() + 1);
      if (
        fromDate.getDay() != 0 &&
        !disabledDates.find((dDate) =>
          isSameDay(parseISO(dDate.holiday_date), fromDate)
        )
      )
        count++;
    }
    return fromDate;
  };

  const columns = React.useMemo(
    () => [
      { Header: 'Booking Id', accessor: 'id' },
      { Header: 'Stand Id', accessor: 'stand_id' },
      {
        Header: 'User Id',
        accessor: 'user_id',
        Cell: ({ row }) => (
          <span
            onClick={() => {
              const data = {
                ID: row.original?.user?.id,
                'First Name': row.original?.user?.first_name,
                'Last Name': row.original?.user?.last_name,
                Email: row.original?.user?.email,
                Phone: row.original?.user?.phone_number,
                Country: row.original?.user?.country,
                Zip: row.original?.user?.zip,
                Address: row.original?.user?.address,
                IBAN: row.original?.user?.iban,
                'Created At': row.original?.user?.createdAt,
              };
              setUser(
                Object.entries(data).map(([name, value]) => ({
                  name,
                  value,
                }))
              );
              handleClickOpen();
            }}
            style={{
              backgroundColor: '#feefc7',
              color: 'black',
              padding: '8px 25px',
              cursor: 'pointer',
            }}
          >
            {row.original?.user?.id}
          </span>
        ),
      },
      { Header: 'Duration', accessor: 'duration' },
      { Header: 'Package', accessor: 'collect_product_by' },
      {
        Header: 'Start Date',
        accessor: 'start',
        Cell: (row) => moment(row.value).utc().format('DD-MM-YYYY'),
      },
      {
        Header: 'End Date',
        accessor: 'end',
        Cell: (row) => moment(row.value).utc().format('DD-MM-YYYY'),
      },
      { Header: 'Rent Price', accessor: 'rent_price' },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (row) => (row.value === '1' ? 'Active' : 'Inactive'),
      },
      {
        Header: 'Remaining Time',
        accessor: '',
        Cell: ({ row }) => <Countdown date={moment(row.original?.end)} />,
      },
      {
        Header: 'Actions',
        accessor: '',
        Cell: ({ row }) => (
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button
              onClick={async () => {
                await handleUpdate(row.original.start, row.original.end);
                setUpdatedItemId(row.original?.id);
                setStand_id(row.original?.stand_id);
                setUpdatedStartDate(row.original?.start);
                setUpdatedEndDate(row.original?.end);
                setUpdatedRentPrice(row.original?.rent_price);
                setName(row.original?.holiday_date);
                setUpdate(true);
              }}
              style={{
                backgroundColor: '#d0a667',
                color: 'white',
                marginRight: '8px',
              }}
            >
              Update
            </Button>
            <Button
              onClick={() => cancelBooking(row.original?.id)}
              style={{
                backgroundColor: '#d9534f',
                color: 'white',
              }}
            >
              Cancel
            </Button>
          </div>
        ),
      },
    ],
    [handleUpdate, setUpdatedItemId, setName, setUpdate]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: filterProducts }, useSortBy);

  const { filteredProducts } = React.useMemo(() => {
    return {
      filteredProducts: products.filter((curr) => {
        if (!searchValue) {
          return true;
        }

        const searchLowerCase = searchValue.toLowerCase();
        return (
          curr?.user?.id?.toString().includes(searchLowerCase) ||
          curr?.stand_id?.toString().includes(searchLowerCase) ||
          curr?.collect_product_by?.toLowerCase().includes(searchLowerCase)
        );
      }),
    };
  }, [products, searchValue]);

  React.useEffect(() => {
    setFilterProducts([...filteredProducts]);
  }, [filteredProducts]);

  React.useEffect(() => {
    let updatedProducts = [];

    if (currentTab === 'Current') {
      updatedProducts = filteredProducts.filter(
        (product) =>
          moment(product.start).isSameOrBefore(moment()) &&
          moment(product.end).isSameOrAfter(moment())
      );
    } else if (currentTab === 'Past') {
      updatedProducts = filteredProducts.filter((product) =>
        moment(product.end).isBefore(moment())
      );
    } else if (currentTab === 'Upcoming') {
      updatedProducts = filteredProducts.filter((product) =>
        moment(product.start).isAfter(moment())
      );
    } else {
      updatedProducts = filteredProducts;
    }

    setFilterProducts(updatedProducts);
  }, [currentTab, filteredProducts]);

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
    if (event.target.value === 'existing') {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `/user/get-all-users`,
        headers: {},
      };

      http
        .request(config)
        .then((response) => {
          setUsers(response?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <Box
      sx={{
        m: 'auto',
        p: 2,
        paddingTop: 5,
      }}
      component={Paper}
    >
      <Dialog open={update} onClose={handleUpdateClose}>
        <DialogTitle>Update Stand</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select the stand from the list and update the details.
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputLabel id='stands'>Stands</InputLabel>
              <Select
                labelId='stands'
                id='stands'
                name='stands'
                size='small'
                value={stand_id}
                onChange={(e) => setStand_id(e.target.value)}
                label='Stands'
                style={{ minWidth: '100%' }}
              >
                {standList &&
                  standList.length &&
                  standList.map((stand) => (
                    <MenuItem key={stand?.id} value={stand?.id}>
                      {stand?.label}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='start_date'
                name='start_date'
                label='Start Date'
                type='date'
                value={moment(updatedStartDate).format('yyyy-MM-DD')}
                onChange={(e) => setUpdatedStartDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='end_date'
                name='end_date'
                label='End Date'
                type='date'
                value={moment(updatedEndDate).format('yyyy-MM-DD')}
                onChange={(e) => setUpdatedEndDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id='rent_price'
                name='rent_price'
                label='Rent Price'
                type='number'
                value={updatedRentPrice}
                onChange={(e) => setUpdatedRentPrice(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdateClose}>Cancel</Button>
          <Button onClick={updateProduct}>Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isUserModel} onClose={handleUserModelClose}>
        <DialogTitle>User Detail</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table sx={{ minWidth: 350 }} aria-label='simple table'>
              <TableBody>
                {userInfo.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component='th' scope='row'>
                      {row.name}
                    </TableCell>
                    <TableCell align='right'>{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUserModelClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isBookingModel}
        onClose={handleBookingModelClose}
        fullWidth
        maxWidth='md'
      >
        <DialogTitle>Create Booking</DialogTitle>
        <DialogContent>
          <RadioGroup
            row
            aria-label='userType'
            name='userType'
            value={userType}
            onChange={handleUserTypeChange}
            style={{ marginBottom: '10px' }}
          >
            <FormControlLabel
              value='new'
              control={<Radio />}
              label='New User'
            />
            <FormControlLabel
              value='existing'
              control={<Radio />}
              label='Existing User'
            />
          </RadioGroup>
          {userType === 'new' ? (
            <Box mb={3}>
              <Typography variant='h6' gutterBottom>
                User Details
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id='firstName'
                    name='firstName'
                    label='First Name'
                    size='small'
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id='lastName'
                    name='lastName'
                    label='Last Name'
                    size='small'
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id='email'
                    name='email'
                    label='Email'
                    size='small'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id='country'
                    name='country'
                    label='Country'
                    size='small'
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.country && Boolean(formik.errors.country)
                    }
                    helperText={formik.touched.country && formik.errors.country}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id='zip'
                    name='zip'
                    label='Zip'
                    size='small'
                    value={formik.values.zip}
                    onChange={formik.handleChange}
                    error={formik.touched.zip && Boolean(formik.errors.zip)}
                    helperText={formik.touched.zip && formik.errors.zip}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id='iban'
                    name='iban'
                    label='IBAN'
                    size='small'
                    value={formik.values.iban}
                    onChange={formik.handleChange}
                    error={formik.touched.iban && Boolean(formik.errors.iban)}
                    helperText={formik.touched.iban && formik.errors.iban}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id='address'
                    name='address'
                    label='Address'
                    size='small'
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id='phoneNumber'
                    name='phoneNumber'
                    label='Phone Number'
                    size='small'
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phoneNumber &&
                      Boolean(formik.errors.phoneNumber)
                    }
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                    required
                  />
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box mb={3}>
              <Typography variant='h6' gutterBottom>
                User Details
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <InputLabel id='existingUserLabel'>Select User</InputLabel>
                  <Select
                    labelId='existingUserLabel'
                    id='existingUser'
                    name='existingUser'
                    value={formik.values.existingUser}
                    onChange={formik.handleChange}
                    fullWidth
                    size='small'
                    required
                    error={
                      formik.touched.existingUser &&
                      Boolean(formik.errors.existingUser)
                    }
                  >
                    {users.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        (ID {user.id}) {user.first_name} {user.last_name} (
                        {user.email})
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.errors.existingUser &&
                    formik.touched.existingUser && (
                      <div style={{ color: 'red', padding: 2 }}>
                        {formik.errors.existingUser}
                      </div>
                    )}
                </Grid>
              </Grid>
            </Box>
          )}
          <Box mb={3}>
            <Typography variant='h6' gutterBottom>
              Booking Details
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <InputLabel
                  sx={{ fontWeight: 700, fontSize: '25px' }}
                  shrink
                  htmlFor='package'
                >
                  Package
                  <span style={{ color: 'red', fontSize: '27px' }}>*</span>
                </InputLabel>
                <Select
                  labelId='package'
                  id='package'
                  name='package'
                  size='small'
                  value={formik?.values?.package}
                  onChange={(e) => {
                    formik.setFieldValue('package', e.target.value);
                    formik.setFieldValue('start_date', '');
                    formik.setFieldValue('end_date', '');
                    formik.setFieldValue('rent_price', '');
                  }}
                  label='Package*'
                  style={{ minWidth: '100%' }}
                  required
                  error={
                    formik.touched.package && Boolean(formik.errors.package)
                  }
                >
                  {packages.map((pkg) => (
                    <MenuItem key={pkg.id} value={pkg.id}>
                      {pkg.title} (€ {pkg.price})
                    </MenuItem>
                  ))}
                  <MenuItem key='custom' value='custom'>
                    Custom
                  </MenuItem>
                </Select>
                {formik.errors.package && formik.touched.package && (
                  <div style={{ color: 'red', padding: 2 }}>
                    {formik.errors.package}
                  </div>
                )}
              </Grid>
              {formik.values.package && formik.values.package === 'custom' && (
                <>
                  <Grid item xs={12} sm={6}>
                    <InputLabel
                      sx={{ fontWeight: '700', fontSize: '25px' }}
                      shrink
                      htmlFor='start_date'
                    >
                      Start Date
                      <span style={{ color: 'red', fontSize: '27px' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      id='start_date'
                      name='start_date'
                      size='small'
                      value={formik.values.start_date}
                      onChange={formik.handleChange}
                      type='date'
                      sx={{
                        '& label': {
                          color: 'black',
                        },
                        '& input': {
                          backgroundColor: '#fdfcf6',
                        },
                      }}
                      required
                      error={
                        formik.touched.start_date &&
                        Boolean(formik.errors.start_date)
                      }
                      helperText={
                        formik.touched.start_date && formik.errors.start_date
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel
                      sx={{ fontWeight: '700', fontSize: '25px' }}
                      shrink
                      htmlFor='end_date'
                    >
                      End Date
                      <span style={{ color: 'red', fontSize: '27px' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      id='end_date'
                      name='end_date'
                      size='small'
                      value={formik.values.end_date}
                      onChange={(e) => {
                        formik.handleChange(e);

                        if (formik.values.start_date) {
                          const startDate = new Date(formik.values.start_date);
                          const endDate = new Date(e.target.value);

                          if (endDate > startDate) {
                            fetchAvailableStands(
                              moment(startDate).format('yyyy-MM-DD'),
                              moment(endDate).format('yyyy-MM-DD')
                            );
                          } else {
                            alert('End date must be after the start date.');
                          }
                        }
                      }}
                      type='date'
                      sx={{
                        '& label': {
                          color: 'black',
                        },
                        '& input': {
                          backgroundColor: '#fdfcf6',
                        },
                      }}
                      required
                      error={
                        formik.touched.end_date &&
                        Boolean(formik.errors.end_date)
                      }
                      helperText={
                        formik.touched.end_date && formik.errors.end_date
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel
                      sx={{ fontWeight: 700, fontSize: '25px' }}
                      shrink
                      htmlFor='rent_price'
                    >
                      Rent Price (€)
                      <span style={{ color: 'red', fontSize: '27px' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      id='rent_price'
                      name='rent_price'
                      size='small'
                      type='number'
                      value={formik.values.rent_price}
                      onChange={formik.handleChange}
                      sx={{
                        '& label': {
                          color: 'black',
                        },
                        '& input': {
                          backgroundColor: '#fdfcf6',
                        },
                      }}
                      required
                      error={
                        formik.touched.rent_price &&
                        Boolean(formik.errors.rent_price)
                      }
                      helperText={
                        formik.touched.rent_price && formik.errors.rent_price
                      }
                    />
                  </Grid>
                </>
              )}
              {formik.values.package && formik.values.package !== 'custom' && (
                <>
                  <Grid item xs={12} sm={6}>
                    <InputLabel
                      sx={{ fontWeight: '700', fontSize: '25px' }}
                      shrink
                      htmlFor='start_date'
                    >
                      Start Date
                      <span style={{ color: 'red', fontSize: '27px' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      id='start_date'
                      name='start_date'
                      size='small'
                      value={formik.values.start_date}
                      onChange={(e) => {
                        if (formik.values.package === '') {
                          alert('Bitte wählen Sie zuerst das Paket aus');
                          return;
                        }
                        const selectDayItem = packages.find(
                          (pkg) => pkg.id === Number(formik.values.package)
                        );
                        const workingDays = calcWorkingDays(
                          new Date(e.target.value),
                          selectDayItem?.duration
                        );
                        formik.setFieldValue(
                          'start_date',
                          moment(new Date(e.target.value)).format('yyyy-MM-DD')
                        );
                        formik.setFieldValue(
                          'end_date',
                          moment(workingDays).format('yyyy-MM-DD')
                        );
                        fetchAvailableStands(
                          e.target.value,
                          moment(workingDays).format('yyyy-MM-DD')
                        );
                      }}
                      type='date'
                      sx={{
                        '& label': {
                          color: 'black',
                        },
                        '& input': {
                          backgroundColor: '#fdfcf6',
                        },
                      }}
                      required
                      error={
                        formik.touched.start_date &&
                        Boolean(formik.errors.start_date)
                      }
                      helperText={
                        formik.touched.start_date && formik.errors.start_date
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel
                      sx={{ fontWeight: '700', fontSize: '25px' }}
                      shrink
                      htmlFor='end_date'
                    >
                      End Date
                      <span style={{ color: 'red', fontSize: '27px' }}>*</span>
                    </InputLabel>
                    <TextField
                      fullWidth
                      id='end_date'
                      name='end_date'
                      size='small'
                      value={formik.values.end_date}
                      onChange={formik.handleChange}
                      type='date'
                      disabled
                      sx={{
                        '& label': {
                          color: 'black',
                        },
                        '& input': {
                          backgroundColor: '#fdfcf6',
                        },
                      }}
                      required
                      error={
                        formik.touched.end_date &&
                        Boolean(formik.errors.end_date)
                      }
                      helperText={
                        formik.touched.end_date && formik.errors.end_date
                      }
                    />
                  </Grid>
                </>
              )}
              {formik.values.start_date && formik.values.end_date && (
                <Grid item xs={12} sm={6}>
                  <InputLabel
                    sx={{ fontWeight: 700, fontSize: '25px' }}
                    shrink
                    htmlFor='stands'
                  >
                    Stand ID
                    <span style={{ color: 'red', fontSize: '27px' }}>*</span>
                  </InputLabel>
                  <Select
                    labelId='shelf'
                    id='shelf'
                    name='shelf'
                    size='small'
                    value={formik.values.shelf}
                    onChange={formik.handleChange}
                    label='Stand ID'
                    style={{ minWidth: '100%' }}
                    required
                    error={formik.touched.shelf && Boolean(formik.errors.shelf)}
                  >
                    {standList &&
                      standList.length &&
                      standList.map((stand) =>
                        stand.is_available === true &&
                        stand.status === 'active' ? (
                          <MenuItem key={'stand_' + stand.id} value={stand.id}>
                            {stand.label}
                          </MenuItem>
                        ) : null
                      )}
                  </Select>
                  {formik.errors.shelf && formik.touched.shelf && (
                    <div style={{ color: 'red', padding: 2 }}>
                      {formik.errors.shelf}
                    </div>
                  )}
                </Grid>
              )}
              {formik.values.shelf && (
                <Grid item xs={12} sm={6}>
                  <InputLabel
                    sx={{ fontWeight: 700, fontSize: '25px' }}
                    shrink
                    htmlFor='additionalServices'
                  >
                    Additional Services
                    <span style={{ color: 'red', fontSize: '27px' }}>*</span>
                  </InputLabel>
                  <Select
                    labelId='additionalServices'
                    id='additionalServices'
                    name='additionalServices'
                    size='small'
                    value={formik?.values?.additionalServices}
                    onChange={formik.handleChange}
                    label='Additional Services*'
                    style={{ minWidth: '100%' }}
                    required
                    error={
                      formik.touched.additionalServices &&
                      Boolean(formik.errors.additionalServices)
                    }
                  >
                    {extras.map((extra) => (
                      <MenuItem key={extra.id} value={extra.id}>
                        {extra.title} (€ {extra.price})
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.errors.additionalServices &&
                    formik.touched.additionalServices && (
                      <div style={{ color: 'red', padding: 2 }}>
                        {formik.errors.additionalServices}
                      </div>
                    )}
                </Grid>
              )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBookingModelClose}>Cancel</Button>
          <Button onClick={formik.handleSubmit}>Save</Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 3,
          mb: 3,
        }}
      >
        <Typography sx={{ fontSize: '25px', fontWeight: 'bolder' }}>
          Bookings Lists
        </Typography>
        <Button
          onClick={handleClickBookingOpen}
          sx={{
            ':hover': {
              bgcolor: '#e4f0f1',
              color: 'black',
            },
            bgcolor: '#e4f0f1',
            color: 'black',
            fontWeight: 'bold',
            padding: '1rem',
          }}
        >
          Create New Booking
        </Button>
      </Box>

      <Typography
        variant='h6'
        sx={{
          fontSize: '1.25rem',
          fontWeight: 'bold',
          mb: 1,
          color: '#333',
        }}
      >
        Advanced Search
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
        <Select
          value={selectedColumn}
          onChange={(e) => setSelectedColumn(e.target.value)}
          displayEmpty
          fullWidth
          sx={{
            border: '1px solid #ced4da',
            borderRadius: '4px',
            fontSize: '1rem',
            minWidth: '200px',
          }}
        >
          <MenuItem value='' disabled>
            Select Column
          </MenuItem>
          {searchableColumns.map((col) => (
            <MenuItem key={col.value} value={col.value}>
              {col.label}
            </MenuItem>
          ))}
        </Select>

        <TextField
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value)}
          label='Enter Filter Value'
          fullWidth
          sx={{
            borderRadius: '4px',
            fontSize: '1rem',
            minWidth: '200px',
          }}
        />

        <Button
          variant='contained'
          color='primary'
          onClick={handleSearch}
          disabled={isSearchLoading}
          sx={{
            padding: '10px 16px',
            textTransform: 'none',
            ':hover': {
              backgroundColor: '#003366',
            },
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {isSearchLoading ? (
            <CircularProgress size={24} sx={{ color: 'white' }} />
          ) : (
            'Search'
          )}
        </Button>
      </Box>

      <TableContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <select
            style={{ padding: '6px', marginBottom: '6px' }}
            onChange={handleChangeRowsPerPage}
          >
            {[50, 80, 100, 200, 500].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                zeigen {pageSize}
              </option>
            ))}
          </select>
          <div
            style={{ display: 'flex', alignItems: 'center', columnGap: '24px' }}
          >
            <p
              onClick={() => setCurrentTab('Past')}
              style={
                currentTab === 'Past'
                  ? {
                      color: '#e1b6b4',
                      cursor: 'pointer',
                      fontWeight: '1000',
                      borderBottom: '3px solid #e1b6b4',
                    }
                  : { cursor: 'pointer' }
              }
            >
              Past
            </p>
            <p
              onClick={() => setCurrentTab('Current')}
              style={
                currentTab === 'Current'
                  ? {
                      color: '#e1b6b4',
                      cursor: 'pointer',
                      fontWeight: '1000',
                      borderBottom: '3px solid #e1b6b4',
                    }
                  : { cursor: 'pointer' }
              }
            >
              Current
            </p>
            <p
              onClick={() => setCurrentTab('Upcoming')}
              style={
                currentTab === 'Upcoming'
                  ? {
                      color: '#e1b6b4',
                      cursor: 'pointer',
                      fontWeight: '1000',
                      borderBottom: '3px solid #e1b6b4',
                    }
                  : { cursor: 'pointer' }
              }
            >
              Upcoming
            </p>
          </div>
          <input
            type='search'
            onChange={(event) => setSearchValue(event.target.value)}
            placeholder='Suche...'
            style={{ padding: '8px', marginBottom: '6px' }}
          />
        </div>

        <Table {...getTableProps()} aria-label='a dense table'>
          <TableHead sx={{ bgcolor: '#e4f0f1' }}>
            <TableRow>
              {headerGroups.map((headerGroup) => (
                <React.Fragment key={headerGroup.id}>
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      key={column.id}
                      align='left'
                      sx={{
                        fontFamily: 'sans-serif',
                        fontSize: '0.82rem',
                        fontWeight: '600',
                      }}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDropDownIcon
                            sx={{
                              verticalAlign: 'middle',
                            }}
                          />
                        ) : (
                          <ArrowDropUpIcon
                            sx={{
                              verticalAlign: 'middle',
                            }}
                          />
                        )
                      ) : null}
                    </TableCell>
                  ))}
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow
                  key={row.id}
                  sx={{
                    background: '#F9F9F9 ',
                  }}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <TableCell key={cell.id} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'end' }}>
        <Pagination
          page={page - 1}
          products={products}
          rowsPerPage={rowsPerPage}
          totalPages={totalPages}
          handleFirstPageButtonClick={() => setPage(1)}
          handleBackButtonClick={() => setPage(page - 1)}
          handleNextButtonClick={() => setPage(page + 1)}
          handleLastPageButtonClick={() => setPage(totalPages)}
          handleChangePage={handlePageChange}
        />
      </Box>
    </Box>
  );
}
