import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import http from "../../http";
import { useNavigate, Link } from "react-router-dom";
import { Spinner } from "../Spinner/Spinner";

const Dashboard = (props) => {
  const navigate = useNavigate();

  const [showRating, setShowRating] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [stats, setStats] = React.useState(null);
  const [allData, setAllData] = React.useState();
  const [userEarnings, setUserEarnings] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
    navigate("/my-profile/stands/2");
  };

  const handleImageClick = () => {
    navigate("/my-profile/couponDetails");
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    http
      .request({
        method: "get",
        maxBodyLength: Infinity,
        url: "/rating/has-user-submitted/rating",
      })
      .then((response) => {
        const { data } = response.data;
        setShowRating(data?.status);
      })
      .catch((error) => {
        console.log(error);
      });
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "/stats",
    };
    http
      .request(config)
      .then((response) => {
        const { data } = response.data;
        setStats(data);
      })
      .catch((error) => {
        console.log(error);
      });
    const fetchUserEarnings = async () => {
      try {
        const response = await http.get("/booking/get-user-bookings-earnings");
        setUserEarnings(response.data.response.data);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching user earnings", error);
        setLoading(false);
      }
    };
    const fetchUserData = async () => {
      try {
        const response = await http.get("/user"); // Replace with the appropriate API endpoint URL
        const productData = await http.get(
          `/product/get-all-by-user-id-filter/${response.data.data.id}`
        );
        setAllData(productData.data.data);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching user data", error);
        setLoading(false);
      }
    };
    fetchUserEarnings();
    fetchUserData();
  }, []);

  if (loading)
    return (
      <Spinner
        text={"Umsätze werden übertragen - das kann einen Moment dauern"}
      />
    );

  return (
    <Box sx={{ minHeight: "100vh" }}>
      <Box
        sx={{
          display: { xs: "block", sm: "flex" },
          justifyContent: "center",
          gap: "2rem",
          // background: "lightGray",
          margin: "auto",
          mt: 3,
        }}
      >
        <Box
          sx={{
            background: "white",
            borderRadius: "2rem",
            border: "2px solid lightgray",
            width: { xs: "100%", sm: "35%", md: "25%" },
            height: "fit-content",
            pt: "0.5rem",
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            justifyContent: "end",
            mt: { xs: "1rem", sm: "0" },
          }}
        >
          <Box
            sx={{ fontSize: "16px", fontWeight: "600", textAlign: "center" }}
          >
            <Typography
              sx={{
                py: "0rem",
                fontWeight: "700",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Mein Umsatz
            </Typography>
            Aktueller Umsatz:
          </Box>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              textAlign: "center",
              padding: "5%",
            }}
          >
            {userEarnings?.currentEarnings !== undefined
              ? `${userEarnings.currentEarnings} € `
              : "Sitzung abgelaufen - bitte aktualisieren!"}
          </Typography>
          <Box
            sx={{ fontSize: "16px", fontWeight: "600", textAlign: "center" }}
          >
            Bereits ausgezahlt:
          </Box>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "600",
              textAlign: "center",
              padding: "5%",
            }}
          >
            {userEarnings?.totalEarnings !== undefined
              ? `${userEarnings.totalEarnings} € `
              : "Sitzung abgelaufen - bitte aktualisieren!"}
          </Typography>
        </Box>
      </Box>

      <Stack direction="column" width="100%" alignItems="center" gap={14}>
        <Box
          sx={{
            background: "#fdfcf6",
            display: { xs: "block", sm: "flex" },
            gap: "1rem",
            justifyContent: "space-around",
            width: "80%",
            m: "auto",
            height: "130px",
            p: 1,
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "32%" },
              background: "#fff",
              mt: {xs: "1rem", sm: "1rem"},
              marginTop: "1rem",
              mb: { xs: "1rem", sm: "0" },
              p: 3,
              height: "fit-content",
              boxShadow: 2,
              borderRadius: "2rem",
              border: "2px solid lightgray",
            }}
          >
            <Typography
              sx={{
                py: "0rem",
                fontWeight: "700",
                textAlign: "center",
                fontSize: "20px",
              }}
            >
              {" "}
              Verkaufte Produkte
            </Typography>
            <Box
              sx={{
                pb: ".5rem",
                marginTop: "0.5em",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{ py: "0rem", fontWeight: "700", textAlign: "center" }}
              >
                {" "}
                Aktuelle Buchung
              </Typography>
              {userEarnings?.currentQuantities !== undefined
                ? `${userEarnings?.currentQuantities}`
                : "-"}
            </Box>
            <Box
              sx={{
                pb: ".5rem",
                marginTop: "0.5em",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{ py: "0rem", fontWeight: "700", textAlign: "center" }}
              >
                {" "}
                Abgeschlossene Buchungen
              </Typography>
              {userEarnings?.totalQuantities !== undefined
                ? `${userEarnings?.totalQuantities}`
                : "-"}
            </Box>
          </Box>
        </Box>
        <Box
          component="img"
          src="https://minmini.de/images/coupon.png"
          alt="Coupon Detail Icon"
          sx={{
            width: { xs: "100%", sm: "35%", md: "25%" },
            height: "auto",
            marginBottom: 0,
            display: { xs: "block", sm: "flex" },
            cursor: "pointer",
          }}
          onClick={handleImageClick}
        />
      </Stack>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "4rem",
          mt: "2rem",
          mb: "2rem",
        }}
      >
        <Button
          style={{ background: "#b89981" }}
          variant="contained"
          size="large"
          onClick={handleClickOpen}
        >
          Buchung verlängern
        </Button>
        <Link to={"https://g.page/r/CXbzk_e_uMhPEBM/review"} target="__blank">
          <Button
            style={{ background: "#686c7d" }}
            variant="contained"
            size="large"
            disabled={showRating}
          >
            BEWERTEN SIE UNS
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default Dashboard;
